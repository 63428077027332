import React from "react"
// import { useLocation } from "@reach/router"
const blogs = require("data/blog.json")

export const Recommend = ({ isTop = true }) => {
  // const title = "タイトル"
  // const route = useLocation()

  const recommend = blogs.filter(_ => _.categories.includes("favorite"))

  return (
    <>
      <div className="access-ranking md:mb-10">
        <h2
          className={`${
            isTop ? `text-xl mb-1` : `text-base mb-3`
          } text-title font-bold blog-aside`}
        >
          おすすめ記事
        </h2>
        <ul className="w-full md:flex md:flex-wrap">
          {recommend.map((blog, index) => {
            const imageUrl = blog.imageUrl
            return (
              <li
                key={index}
                className={`${
                  isTop
                    ? `md:w-[calc((100%_-_80px)_/_3)] md:mr-10 md:[&:nth-child(3)]:mr-0 md:py-6 md:first:pt-6 py-2`
                    : `py-2`
                } border-b border-middlegrey last:border-none`}
              >
                <a className="" href={blog.href}>
                  <div
                    className={`${
                      isTop ? `flex-row md:flex-col gap-4` : `md:flex-row gap-3`
                    } flex`}
                  >
                    <div
                      className={`${
                        isTop ? `w-1/3 md:w-full order-2 md:order-1` : `flex-1`
                      }`}
                    >
                      <img
                        src={imageUrl}
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt={blog.alt}
                        className="object-contain w-full"
                      />
                    </div>
                    <p
                      className={`${
                        isTop
                          ? `w-2/3 md:w-full order-1 md:order-2 line-clamp-3 text-sm`
                          : `w-1/2 md:line-clamp-3 line-clamp-4 text-xs leading-relaxed`
                      }`}
                    >
                      {blog.title}
                    </p>
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
        <hr className="md:hidden w-full border-0 border-t-4 border-palegrey mt-6 mb-8" />
      </div>
    </>
  )
}

// export default Recommend
