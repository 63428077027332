import React from "react"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { Pagination } from "components/pagination"
// import { useLocation } from "@reach/router"
const blogs = require("data/blog.json")

export const AccessRanking = ({ isTop = true }) => {
  const accessRanking = blogs.filter(_ => _.accessRanking.length > 0)
  const accessRankingCol1 = accessRanking.filter(_ => _.accessRanking[0] <= 5)
  // const accessRankingCol2 = accessRanking.filter(_ => _.accessRanking[0] >= 6)

  return (
    <>
      <div className="access-ranking md:mb-14">
        <h2
          className={`${
            isTop ? `text-xl mb-5` : `text-base mb-3`
          } text-title font-bold blog-aside`}
        >
          アクセスランキング
        </h2>
        <ul className="w-full">
          {accessRankingCol1.map((blog, index) => {
            return (
              <li
                key={index}
                className={`${
                  isTop ? `md:py-6 py-2` : `py-2`
                } border-b border-middlegrey last:border-none first:pt-0`}
              >
                <a className="flex md:gap-6 gap-4" href={blog.href}>
                  <div className="flex gap-4 md:flex-1 w-2/3">
                    <p className="font-bold text-accent text-xl font-mont">
                      {blog.accessRanking[0]}.
                    </p>
                    <p
                      className={`${
                        isTop
                          ? `md:text-base text-sm md:font-bold font-normal`
                          : `text-xs font-normal line-clamp-4`
                      }`}
                    >
                      {blog.title}
                      <em className="text-accent text-xs font-bold ml-2">
                        {blog.accessRanking[1]}
                      </em>
                    </p>
                  </div>
                  <div className={`${isTop ? `md:flex-1 w-1/3` : `hidden`}`}>
                    <img
                      src={blog.imageUrl}
                      alt={blog.alt}
                      className="w-full"
                    />
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
        <hr className="md:hidden w-full border-0 border-t-4 border-palegrey mt-6 mb-8" />
      </div>
    </>
  )
}
