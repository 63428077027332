import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
// import { Author } from "../../components/author"

const CerezoOsakaYanmarLadies = ({ location }) => {
  const title =
    "【導入事例】フォロワー数8倍！セレッソ大阪ヤンマーレディースの認知拡大を支えたSNSキャンペーン運用とは？"
  const list = [
    { title: "導入事例", link: "/showcases" },
    { title: title, link: location.pathname },
  ]
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【導入事例】フォロワー数8倍！セレッソ大阪ヤンマーレディースの認知拡大を支えたSNSキャンペーン運用とは？"
          pagedesc="フォロー＆リポストインスタントウィンをはじめとする、SNSマーケ支援の具体例をご紹介。企画立案からキャンペーン実施、自動化システム利用と振り返りまで丸ごと依頼できる「キャンペーンおまかせプラン」とは？企業のマーケ担当者必見です。"
          pageimg="/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_1.jpg"
          pagepath="/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_1.jpg"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は8分で読むことができます
            </div>
            <h1>
              【導入事例】フォロワー数8倍！セレッソ大阪ヤンマーレディースの認知拡大を支えたSNSキャンペーン運用とは？
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            <time className="blog__time">2024.4.2</time>
            <figure className="blog__thumb">
              <img
                className="w-full"
                placeholder="tracedSVG"
                alt="セレッソ大阪ヤンマーレディースキャッチ画像"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_1.jpg"
              />
              <p>
                企業のマーケティング領域でも重要性を増すSNS。そんなSNSを活用したキャンペーン施策を実施し、「フォロワー数8倍」を実現したセレッソ大阪ヤンマーレディース（以下、C大阪）の広報責任者の佐野様、広報担当者の辻様から、実施に至った背景、具体的な取り組み、そして現状の成果などについてお話をお伺いしました。
              </p>
              <br />

              <div className="blog__border-highlight">
                <p>
                  日本女子サッカーのプロリーグ「WEリーグ」に2023年から新規参戦したセレッソ大阪ヤンマーレディース。前身のセレッソ大阪堺レディース時代から、クラブの知名度向上やスタジアムへの来場者増加を目指し、 X/Twitterでの投稿を行っていました。しかし、既存サポーター以外への訴求が課題となり、思うような成果が出ない状況が続いていました。
                </p>
                <p>
                  そんな中、SNSキャンペーン運用をまるごと依頼できるPARKLoTの「キャンペーンおまかせプラン」の導入を決意。
                </p>
                <p>
                  PARKLoTは、X/Twitterキャンペーンの企画立案からノウハウ提供、バナーの制作や自動化システムの提供、さらには分析ファイルを作成して改善までサポート。セレッソ大阪ヤンマーレディース様のSNS強化を通じて、クラブの躍進を支えました。
                </p>
              </div>
              <br />

              <p>
                PARKLoTを利用することで、限られたリソースでもフォロワー数やインプレッション数の増加など確実な成果につながります。今回は、C大阪のSNSマーケティングを支えたキャンペーン支援の内容と、フォロワー数が飛躍的に伸びた理由をご紹介いたします。
              </p>

            </figure>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    X/Twitterでの発信力拡大を目指して
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    フォロワー数が8倍に！平均入場数もWEリーグでトップクラスに
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    最終的な目標から逆算したキャンペーン設計で、確実な成果につながる
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    導入企業様の声：「期待以上の成果に感謝」
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        （1）期待を超えるフォロワー数増加
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        （2）キャンペーン後の運用分析レポートでPDCAを回せる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        （3）リソースがなくても効果的なキャンペーンを開催可能
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        （4）今後の展望とPARKLoTに期待すること
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id9" className="toc__link">
                    キャンペーンおまかせプランについて
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">
                X/Twitterでの発信力拡大を目指して
              </h2>
              <img
                layout="fullWidth"
                placeholder="tracedSVG"
                alt="セレッソ大阪ヤンマーレディースX公式画像"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_2.png"
              />

              <h3>ーまずはSNSをどのような位置付けで運用していらっしゃるか教えてください</h3>
              <p>
                <b>佐野様：</b>
                「クラブのことをできるだけ多くの人に知ってもらう手段として、今の時代SNSの利用は不可欠だと考えています。その中でも、拡散力とリアルタイム性に優れた X/Twitterでのつながり構築は重要です」
              </p>

              <p>
                <b>辻様：</b>
                「具体的には、これまでサッカーに関心が薄かった方にSNSをきっかけにクラブと出会い、試合の配信やアカウント投稿でより深く知ってもらい、最終的にはスタジアムで熱いプレーを観戦してもらいたいというゴールを持っています」
              </p>
              <br />
              <h3>ープレゼントキャンペーンはPARKLoTの導入後に始めたのですか？</h3>
              <p>
                <b>辻様：</b>
                「プレゼントキャンペーン自体は、クラブ内で企画をして開催したことがありました。ただ、リソース的にSNSマーケティングにあまり注力できる状態ではなかったため、フォロワー数を増やすなど十分な成果を上げることができませんでした」
              </p>
              <br />

              <h3>ーPARKLoTを導入してキャンペーンを実施したきっかけは何だったのでしょうか？</h3>
              <p>
                <b>辻様：</b>
                「導入のきっかけは、C大阪のメンズチームがPARKLoTを活用して期待以上の成果を出していたことです。ただ、このようなサービスを活用するのは始めてだったので、導入前は不安もありました。これまでのように伸びない結果になってしまうのではないか、またキャンペーン期間が終わったら、一斉にフォロー解除が起こるのではないかと思っていたんです」
              </p>
              <br />

              <p>
                PARKLoTは打ち合わせの中で、フォロワー獲得へのプランを詳細に説明。賞品設定やバナーなどの細かい点の疑問にもお答えしました。また、豊富な他社のキャンペーン支援経験に基づく動向の共有など、綿密なプランニングで不安を解消できたことが、導入の決め手になったそうです。
              </p>
              <br />

              <p>
                <b>佐野様：</b>
                「キャンペーンで広い層にリーチし、そこでフォローしてくれた人に対して最適な情報を発信していく流れに納得感がありました。まずはSNSをきっかけにクラブや選手に興味を持ち、どんどんファンになっていただきたいと思っていたので、まさにその通りだなと。聞いていた通り、実際のキャンペーン終了後にもフォロー解除はほとんど起こらなかったので、PARKLoTさんにご依頼してよかったと感じています」
              </p>
            </div>
            <div>
              <h2 id="id2">フォロワー数が8倍に！平均入場数もWEリーグでトップクラスに</h2>
              <p>
                今回「セレッソ大阪ヤンマーレディース（@cerezo_ladies）」のX公式アカウントで、クラブの認知拡大＆来場促進のために実施したキャンペーンは以下の通りです。
              </p>
              <br />
              <h3>◇キャンペーン概要◇</h3>
              <ul>
                <li>期間：約8ヶ月（2023年7月〜2024年3月）</li>
                <li>キャンペーン開催回数：8回</li>
                <li>1回のキャンペーン期間：3〜7日間</li>
                <li>キャンペーン開催一覧</li>
              </ul>
              <table className="table-auto">
                <thead>
                  <tr>
                    <th className="blog__text-center bg-palegrey">　</th>
                    <th className="blog__text-center bg-palegrey">キャンペーン（CP）</th>
                    <th className="blog__text-center bg-palegrey">　期間　</th>
                    <th className="blog__text-center bg-palegrey">応募方法</th>
                    <th className="blog__text-center bg-palegrey">景品</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold bg-palegrey">1</td>
                    <td>ユニフォーム発売記念CP</td>
                    <td>5日間</td>
                    <td>フォロー＆リポスト</td>
                    <td>
                      チームユニフォーム（1名）
                      <br />
                      特製デザインQUOカードPay500円分（40名）
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">2</td>
                    <td>ユニフォーム発売記念CP</td>
                    <td>7日間</td>
                    <td>フォロー＆リポスト</td>
                    <td>
                      特製デザインQUOカードPay1,000円分（10名）
                      <br />
                      特製デザインQUOカードPay500円分（20名）
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">3</td>
                    <td>10/1ホームゲームCP</td>
                    <td>7日間</td>
                    <td>フォロー＆リポスト</td>
                    <td>
                      特製デザインQUOカードPay500円分（40名）
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">4</td>
                    <td>ハロウィンCP</td>
                    <td>7日間</td>
                    <td>フォロー＆リポスト</td>
                    <td>
                      特製デザインQUOカードPay500円分（40名）
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">5</td>
                    <td>＃C⼤阪桜花爛漫
                      <br />
                      ハッシュタグCP
                    </td>
                    <td>7日間</td>
                    <td>フォロー＆ハッシュタグ</td>
                    <td>
                      タオルマフラー（5名）
                      <br />
                      特製デザインQUOカードPay5,000円分（4名）
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">6</td>
                    <td>クリスマスCP</td>
                    <td>7日間</td>
                    <td>フォロー＆リポスト
                      <br />
                      ＆ハッシュタグ
                    </td>
                    <td>
                      デジタル招待券 25組50名様
                      <br />
                      特製デザインQUOカードPay500円分（40名）
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">7</td>
                    <td>新春運試しCP×3弾</td>
                    <td>3日間</td>
                    <td>フォロー＆リポスト</td>
                    <td>
                      非売品AWAYユニフォーム×1名
                      <br />
                      特製デザインQUOカードPay 3,000円分（1名）
                      <br />
                      特製デザインQUOカードPay 1,000円分（2名）
                      <br />
                      特製デザインQUOカードPay 300円分 （10名）
                      <br />
                      ※QUOカードPayは全て3回実施
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">8</td>
                    <td>＃勝ちドーナツCP</td>
                    <td>7日間</td>
                    <td>フォロー＆リポスト
                      <br />
                      ＆ハッシュタグ
                    </td>
                    <td>
                      ミスタードーナツカード 1,000円分（20名）
                      <br />
                      特製デザインQUOカードPay500円分（40名）
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <div className="blog__border-highlight">
                <p>今回ご利用いただいたキャンペーン種別について詳しくは以下のページをご覧下さい。</p>
                <p>
                  ▶︎
                  <a
                    href="/case-study/twitter/instant-win-digital-gift"
                    target="_blank"
                    rel="noreferrer"
                  >
                    フォロー＆リポスト（インスタントウィン）
                  </a>
                </p>
                <p>
                  ▶︎
                  <a
                    href="/case-study/twitter/follow-and-quote-tweet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    フォロー＆ハッシュタグ引用リツイート
                  </a>
                </p>
              </div>
              <br />
              <br />

              <h3>■キャンペーンの流れ</h3>
              <br />
              <b>【フォロー＆リポスト（インスタントウィン）の場合】</b>
              <ol>
                <li>Xでフォロー＆リポストする</li>
                <li>自動抽選でその場で当落をお知らせ</li>
                <li>当選者にDMを送信してQUOカードPayをプレゼント</li>
                <li>ユニフォームやタオルマフラーなどの景品は締切後に当選者を抽選して送付</li>
              </ol>
              <br />

              <b>【フォロー＆ハッシュタグ引用リツイートの場合】</b>
              <ol>
                <li>Xでフォローする</li>
                <li>キャンペーンの投稿にハッシュタグをつけて引用リポスト</li>
                <li>後日結果を通知・景品をプレゼント</li>
              </ol>
              <br />

              <p>
                PARKLoTで合計8回のキャンペーンを行った結果、導入前は2,000人ほどだったフォロワー数が16,000人まで増加しました。平均入場数も大幅に増えて、WEリーグの中でトップクラスの入場者数を記録しています。
              </p>
              <br />
              <p>
                <b>辻様：</b>
                「PARKLoTさんとの取り組みが始まったときは、フォロワー数が12チーム中11位でした。それが現在に至っては、リーグトップを目指せるほどフォロワー数が増えているので、導入の効果は非常に大きかったと思います。」
              </p>
              <br />
              <p>
                <b>佐野様：</b>
                「また、パートナー企業様との取り組みにもつながっています。2024年2月に始まった『勝ちドーナツキャンペーン』では、ミスタードーナツを運営されているダスキン様にご協力いただきました。先方から『ご紹介いただいて非常にありがたいです』と感謝のお言葉をいただき、私たちも本当にうれしいです」
              </p>
              <br />
              <div className="flex">
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディース勝ちドーナツキャンペーンバナー画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_3.png"
                  />
                </div>
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディース勝ちドーナツキャンペーンポスト画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_4.png"
                  />
                </div>
              </div>
            </div>
            <div>
              <h2 id="id3">
                最終的な目標から逆算したキャンペーン設計で、確実な成果につながる
              </h2>
              <p>
                今回のキャンペーンを実施するにあたり、PARKLoTからのアドバイスは以下の内容でした。
              </p>
              <br />
              <h3>◇アドバイス◇</h3>
              <ol>
                <li>参加者が増えるように、まずはデジタルギフト券などの汎用性の高い景品を設定する</li>
                <li>最初は応募の簡単なフォロー＆リポスト応募にし、フォロワーが増えてからハッシュタグ応募も採用する</li>
                <li>興味を持ってもらえるように、1人当たりの景品金額よりも当選者数を優先する</li>
                <li>キャンペーン開始は、スマホを触るお昼休みに1次ピークが来るような時間帯にする（2次ピークは夕方〜夜）</li>
                <li>ハロウィンなど季節性の高いキャンペーンは、バナーで楽しさを演出する</li>
              </ol>
              <br />

              <p>
                C大阪でPARKLoT導入前に掲げていた目標は、フォロワー数2,000名→5,000名への増加。ただ、社内で工夫してもなかなか数字を伸ばせなかったため、半ばあきらめていたとのこと。しかし、1回目のキャンペーン期間で5,700名までフォロワーが増えて目標達成。最初のキャンペーンからしっかり成果を出すことができました。
              </p>
              <br />

              <div className="flex">
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディースユニフォーム発売記念キャンペーンバナー画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_5.png"
                  />
                </div>
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディースユニフォーム発売記念キャンペーンポスト画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_6.png"
                  />
                </div>
              </div>
              <br />
              <p>
                <b>辻様：</b>「依頼してからのレスポンスも早く、とてもスピーディーにバナーも作成していただきました。それまでは選手の画像をそのまま投稿するなど、今考えるとユーザーへの配慮が足りなかったと思います。PARKLoTさんが、バナーでとてもわかりやすい訴求をしてくださったので助かりました」
              </p>
              <br />
              <p>
                2回目、3回目のキャンペーンで、より多くの人に興味を持ってもらえるように景品の当選者数を拡大。景品にかける全体の金額は変わらないものの、ユーザーの参加意欲は大きく変わります。
              </p>
              <br />
              <p>
                また、キャンペーンの成功には、参加者のニーズに合わせた企画の調整が不可欠です。キャンペーン代行を依頼することで、効率的かつ戦略的に企画のブラッシュアップができるようになるのです。
              </p>

              <div className="blog__border-highlight">
                <p>
                  【2回目の景品】<br />
                  QUOカードPay1,000円分を<span className="border-b">10名</span>、500円分を<span className="border-b">20名</span>
                </p>
                <p>↓</p>
                <p>
                  【3回目の景品】<br />
                  QUOカードPay 500円分を<span className="border-b">40名</span>
                </p>
              </div>
              <br />

              <p>
                そして、4回目のハロウィンキャンペーンで、ついにフォロワー数1万人を突破。選手たちが楽しそうにコスプレしていた画像もユーザーに好評だったようです。
              </p>
              <br />

              <div className="flex">
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディースハロウィンキャンペーンバナー画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_7.png"
                  />
                </div>
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディースハロウィンキャンペーンポスト画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_8.png"
                  />
                </div>
              </div>
              <br />

              <p>
                また、キャンペーンの開催はフォロワー獲得以外にもメリットが得られます。それは、フォロワー・参加者がコメントや反応をするきっかけになるということです。実際に、ハロウィンキャンペーンでは、新規フォロワーや既存サポーターから「応援しています」「怪我なくリーグ開幕頑張って」や「現地で応援します！」とコメントが集まりました。キャンペーンを実施することで、ファン・サポーターが公式アカウントを身近に感じ、愛着や一体感の醸成につながります。
              </p>
              <br />
              <p>
                十分なフォロワーを確保した5回目のキャンペーンからは、ファン化に向けてさらにエンゲージメントを高めるための「ハッシュタグキャンペーン」も開催。景品もタオルマフラーにして、スタジアムで思わず応援したくなるようなキャンペーンを企画していきました。
              </p>
              <br />
              <p>
                その後、6回目の「クリスマスキャンペーン」では、無料の招待券もプレゼントして、多数の来場者数を記録しています。
              </p>
              <br />

              <div className="flex">
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディースクリスマスキャンペーンバナー画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_9.png"
                  />
                </div>
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディースクリスマスキャンペーンポスト画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_10.png"
                  />
                </div>
              </div>
              <br />

              <p>
                元旦から開催した7回目のキャンペーンでは、少し趣向を変えて1日ごとに締め切りを設定。3日間連続で応募できることで、フォロワー数のさらなる増加につながりました。自動抽選システムを利用した「おみくじ」形式も、参加者から「運試し要素がわくわくする」と好評でした。
              </p>
              <br />

              <div className="flex">
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディース新春運試しキャンペーンバナー画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_11.png"
                  />
                </div>
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="セレッソ大阪ヤンマーレディース新春運試しキャンペーンポスト画像"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/cerezo_osaka_yanmar_ladies/cerezo_osaka_yanmar_ladies_12.png"
                  />
                </div>
              </div>
              <br />
            </div>
            <div>
              <h2 id="id4">導入企業様の声：「期待以上の成果に感謝」</h2>
              <p>
                全8回のXキャンペーン後には、C大阪の担当者様から以下のような感想をいただきました。
              </p>
              <br />
              <h3 id="id5">（1）期待を超えるフォロワー数増加</h3>
              <p>
                <b>佐野様：</b>「最初はフォロワー数2,000名からスタートしていて、いろいろ努力したもののほとんど増やせなかったので、正直無理だと思っていました。しかし、最初に設定していた5,000名をすぐに超えて、現在はその3倍以上の16,000名以上のフォロワー数に。私たちが思っていた以上の結果を出していただき、本当にすごいと感じています」
              </p>
              <br />

              <h3 id="id6">（2）キャンペーン後の運用分析レポートでPDCAを回せる</h3>
              <p>
                <b>辻様：</b>「毎回キャンペーンが終わった後には、詳細な運用分析レポートを出していただけるので改善をスムーズに進められます。『こういう声がありましたよ』とユーザーのリアルな反応も毎回拾っていただいていたので、社内の報告としても非常に助かりましたね」
              </p>
              <br />

              <h3 id="id7">（3）リソースがなくても効果的なキャンペーンを開催可能</h3>
              <p>
                <b>辻様：</b>「ほとんど全ての業務をPARKLoTさんにおまかせできたので、『こんなに何もしなくていいの？』と最初は信じられませんでした。以前はバナーなども単発で外部の会社にお願いしていましたが、それも全てPARKLoTさんに依頼できるので、とにかくリソース面でメリットが大きい思います」
              </p>
              <br />

              <h3 id="id8">（4）今後の展望とPARKLoTに期待すること</h3>
              <p>
                <b>佐野様：</b>「私たちには、SNSでのフォロワー獲得で大きな目標があるので、それを達成するためにはXアカウントだけではなく、InstagramやTikTokなどにも挑戦しようとしています。PARKLoTさんの方でも、Instagramをサポートしていると聞いたので、今後はぜひ別のメディアもサポートしてください」
              </p>
              <br />
            </div>
            <div>
              <h2 id="id9">キャンペーンおまかせプランについて</h2>
              <p>
                X/Twitterキャンペーンの企画立案から実施、分析までのすべてをPARKLoTが代行するプランです。200件超のキャンペーン支援実績を活かしたご提案、バナー制作や景品手配、なかなか手が回らない分析作成まで含めた安心のパッケージプランとなっています。
              </p>
              <br />
              <p>
                SNSマーケティングの成果を最大化しつつ、担当者の業務負担を大幅に削減したい企業様におすすめです。
              </p>
              <p>まずはヒアリングから、お気軽にご相談ください。</p>
              <p>
                  ▶︎
                  <a
                    href="https://hub.park-lot.com/contact"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTお問合せページ
                  </a>
                </p>
              </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default CerezoOsakaYanmarLadies
