import React from "react"

export const Events = ({ isTop = true }) => {
  return (
    <div className="categories">
      <h2
        className={`${
          isTop ? `text-xl mb-5` : `text-base mb-3`
        } text-title font-bold blog-aside`}
      >
        イベント
      </h2>
    </div>
  )
}
