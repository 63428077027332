import React from "react"
const categories = require("../../data/category.json")

export const Categories = ({ isTop = true }) => {
  return (
    <>
      <div className="categories md:mb-14">
        <h2
          className={`${
            isTop ? `text-xl mb-5` : `text-base mb-3`
          } text-title font-bold blog-aside`}
        >
          すべてのカテゴリー
        </h2>
        {categories.map((category, index) => {
          return (
            <span
              key={index}
              className="mb-2 mr-2 px-2 py-1 bg-palegrey text-text rounded text-xs inline-block border border-middlegrey"
            >
              <a href={`/blog/category/${category.path}`}>{category.name}</a>
            </span>
          )
        })}
      </div>
      <hr className="md:hidden w-full border-0 border-t-4 border-palegrey my-8" />
    </>
  )
}
