import React from "react"
import { Recommend } from "components/blog/recommend"
import { AccessRanking } from "components/blog/access-ranking"
import { Tags } from "components/blog/tags"
import { Categories } from "components/blog/categories"
import { Events } from "components/blog/events"

export const ContentsAside = () => {
  return (
    <>
      <aside className="bg-white rounded-xl md:p-6 md:drop-shadow-2xl w-full">
        <AccessRanking isTop={false} />
        <Recommend isTop={false} />
        <Events isTop={false} />
        <Categories isTop={false} />
        <Tags isTop={false} />
      </aside>
    </>
  )
}
