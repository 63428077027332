import React from "react"
import { ContentsAside } from "../components/blog/contents-aside"

export const BlogContainer = ({ children }) => {
  return (
    <>
      <div className="md:flex md:gap-7 w-full md:max-w-6xl md:mx-auto bg-white md:bg-opacity-0">
        <div className="blog px-4 md:mb-20 pb-0 bg-white md:p-14 md:w-auto py-6 md:rounded-xl md:drop-shadow-2xl">
          {children}
        </div>
        <hr className="md:hidden w-full border-0 border-t-4 border-middlegrey mt-6 mb-8" />
        <div className="px-4 md:max-w-xs w-full">
          <ContentsAside />
        </div>
      </div>
    </>
  )
}
